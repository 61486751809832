<template>
  <div>
    <header-slot />
    <filter-slot
      :filter="filters"
      :filter-principal="filterPrincipal"
      :total-rows="totalRows"
      :paginate="paginate"
      :start-page="startPage"
      :to-page="toPage"
      @reload="$refs['refDashboardTracking'].refresh()"
    >
      <template #table>
        <b-table
          ref="refDashboardTracking"
          no-border-collapse
          class="position-relative"
          :fields="fields"
          show-empty
          no-provider-filtering
          sticky-header="50vh"
          primary-key="id"
          responsive="sm"
          :items="trackingProvider"
          :sort-by.sync="sortBy"
          :sort-desc.sync="sortDesc"
          :current-page="paginate.currentPage"
          :per-page="paginate.perPage"
          :filter="searchInput"
          :busy.sync="isBusy"
        >
          <template #table-busy>
            <div class="text-center text-primary my-2">
              <b-spinner class="align-middle mr-1" />
              <strong>Loading ...</strong>
            </div>
          </template>
          <template #cell(sub_module_name)="data">
            <b-badge
              :variant="getVariantColorModule(data.item.sub_module_id)"
              class="w-100"
            >
              <span>{{ data.item.sub_module_name }}</span>
            </b-badge>
          </template>
          <template #cell(started_at)="data">
            <span>{{ data.item.started_at | myGlobalWithHour }}</span>
          </template>
          <template #cell(motive_name)="data">
            <b-badge
              :variant="getVariantColor(data.item.motive_id)"
              class="w-100"
            >
              <span>{{ data.item.motive_name }}</span>
            </b-badge>
          </template>
          <template #cell(action_name)="data">
            <b-badge
              :variant="getVariantColor(data.item.action_id)"
              class="w-100"
            >
              <span>{{ data.item.action_name }}</span>
            </b-badge>
          </template>
          <template #cell(finished_at)="data">
            <span>{{ data.item.finished_at | myGlobalWithHour }}</span>
          </template>
        </b-table>
      </template>
    </filter-slot>
  </div>
</template>

<script>
import DashboardTrackingFilters from "@/views/ce-digital/sub-modules/customer-service/views/dashboard-tracking/data/dashboard-tracking-filters";
import ClientsDashboardServices from "@/views/commons/components/clients/dashboard/services/clients.dashboard.services";
import CedDashboardTracking from "@/views/ce-digital/sub-modules/customer-service/views/dashboard-tracking/service/ced-dashboard-tracking";

export default {
  name: "DashboardTracking",
  data() {
    return {
      filters: DashboardTrackingFilters,
      filterPrincipal: {
        type: "input",
        inputType: "text",
        placeholder: "Search by agent name or client account...",
        model: "",
      },
      fields: [
        {
          key: "full_name",
          label: "Agent",
        },
        {
          key: "motive_name",
          label: "Motive Access",
        },
        {
          key: "action_name",
          label: "Way Access",
        },
        {
          key: "account",
          label: "Account",
        },
        {
          key: "started_at",
          label: "Started at",
          sortable: true,
        },
        {
          key: "finished_at",
          label: "Finished at",
          sortable: true,
        },
      ],
      totalRows: 1,
      paginate: {
        perPage: 10,
        currentPage: 1,
      },
      selectedItem: [],
      startPage: 0,
      toPage: 0,
      isBusy: false,
      sortBy: "started_at",
      sortDesc: false,
      searchInput: "",
      mood: "",
    };
  },
  async created() {
    await this.setUpFiltersData();
  },
  computed: {
    moduleId() {
      return this.$route.matched[0].meta.module;
    },
  },
  methods: {
    async trackingProvider(ctx) {
      const params = {
        text: this.filterPrincipal.model,
        date_from: this.filters[0].model,
        date_to: this.filters[1].model,
        per_page: this.paginate.perPage,
        page: this.paginate.currentPage,
        sub_module_id: this.moduleId,
        agent_id: this.filters[3].model,
        motive_id: this.filters[4].model,
        way_access_id: this.filters[5].model,
        orderby: ctx.sortBy ? ctx.sortBy : "started_at",
        order: ctx.sortDesc ? "asc" : "desc",
      };
      const data = await ClientsDashboardServices.searchDashboardTrackingCed(
        params
      );
      this.items = data.data.data;
      this.startPage = data.data.from;
      this.paginate.currentPage = data.data.current_page;
      this.paginate.perPage = data.data.per_page;
      this.nextPage = this.startPage + 1;
      this.endPage = data.data.last_page;
      this.totalRows = data.data.total;
      this.toPage = data.data.to;
      return this.items || [];
    },
    async setUpFiltersData() {
      const dataSubModules = await CedDashboardTracking.getDigitalSubmodules();
      if (dataSubModules.status === 200) {
        this.filters[2].options = dataSubModules.data;
      }
      const dataMotives = await CedDashboardTracking.getDigitalMotives();
      if (dataMotives.status === 200) {
        this.filters[4].options = dataMotives.data;
      }
      const dataActions = await CedDashboardTracking.getDigitalActions();
      if (dataActions.status === 200) {
        this.filters[5].options = dataActions.data;
      }
      const dataAgents = await CedDashboardTracking.getSubModuleAgents({
        sub_module_id: this.moduleId,
      });
      if (dataAgents.status === 200) {
        this.filters[3].options = dataAgents.data;
      }
    },
    getVariantColor(id) {
      switch (id) {
        case 1:
          return "success";
        case 2:
          return "warning";
        case 3:
          return "info";
        case 4:
          return "light-success";
        case 5:
          return "light-warning";
        case 6:
          return "light-info";
        case 7:
          return "light-primary";
        case 8:
          return "light-secondary";
        case 9:
          return "secondary";
        default:
          return "primary";
      }
    },
    getVariantColorModule(id) {
      switch (id) {
        case 22:
          return "primary";
        case 23:
          return "warning";
        case 26:
          return "info";
        case 27:
          return "light-success";
        case 28:
          return "light-warning";
        default:
          return "success";
      }
    },
  },
};
</script>

<style scoped>
</style>
