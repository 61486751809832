import { render, staticRenderFns } from "./DashboardTracking.vue?vue&type=template&id=b6ebc968&scoped=true"
import script from "./DashboardTracking.vue?vue&type=script&lang=js"
export * from "./DashboardTracking.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "b6ebc968",
  null
  
)

export default component.exports